import * as React from "react";
import { useSelector } from "react-redux";

import jaLocale from "date-fns/locale/ja";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";

import { MuiThemeProvider, makeStyles, createStyles, createMuiTheme, Theme } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";

//import UchuTop from "./UchuTop";
//import UchuTop from "./UchuTopMaintainance";
import UchuTop from "./UchuTopQuestions";
//import Questions from "./Questions";
//import MyPage from "./MyPage";
//import Registration from "./Registration";
import Footer from "./Footer";
import Inquery from "./Inquery";
//import Auth from "./Auth";
import NotifyTooltip from "./NotifyTooltip";
import Header from "./Header";
//import Login from "./Login";
import PrivacyPolicy from "./PrivacyPolicy";
import TOS from "./TOS";
/*
import Closed from "./Closed";
import Withdrawal from "./Withdrawal";
import WithdrawalSuccess from "./WithdrawalSuccess";
*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      backgroundColor: "white",
    }
  })
);

const appTheme = createMuiTheme({
  typography: {
    fontSize: 16,
    button: {
      textTransform: "none",
    }
  },
  palette: {
    primary: {
      light: "#5fceff",
      main: "#009dd4",
      dark: "#002884",
      contrastText: "#ffffff",
    },
    background: {
      default: "white",
    }
  },
  props: {
    MuiCheckbox: {
      color: "primary"
    },
    MuiRadio: {
      color: "primary"
    },
    MuiSwitch: {
      color: "primary"
    },
    MuiList: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    }
  },
  shape: {
    borderRadius: 40,
  },
  overrides: {
  }
});

const components = {
  "home": UchuTop,
  "inquery": Inquery,
  "privacy_policy": PrivacyPolicy,
  "tos": TOS,
  /*
  "closed": Closed,
  "unsubscribe": Login, // Login page
  "withdrawal": Withdrawal,
  "withdrawal_success": WithdrawalSuccess,
  "questions": Questions,
  "mypage": MyPage,
  "registration": Registration,
  "auth": Auth,
  "login": Login,
  */
};

class LocalizedDateFnsUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "yyyy/M/d", { locale: this.locale });
  }
}

const App = (props) => {
  const classes = useStyles({});
  const page = useSelector(state => state.page);

  const MainComponent = components[page];
  const header = (page === "home" || page === "mypage") ? <Header /> : null;
  const footer = page === "questions" ? null : <Footer />;

  return (
    <MuiPickersUtilsProvider utils={LocalizedDateFnsUtils} locale={jaLocale}>
      <MuiThemeProvider theme={appTheme}>
        <CssBaseline />
        { header }
        <div className={classes.root}>
          <MainComponent />
        </div>
        { footer }
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
