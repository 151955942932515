import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { back } from "redux-first-router";

import { makeStyles, createStyles, useTheme, Theme } from "@material-ui/core/styles";

import { WhiteTopBorder, BlueTopBorder, H1, Button } from "./Common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      fontSize: "10pt",
    },
    text: {
      textAlign: "left",
      margin: "auto",
      padding: "10px 0",
      "@media (min-width: 900px)": {
        width: 600,
      },
      "@media (max-width: 899px)": {
        width: "90%",
      },
    },
  })
);

const TOS = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const factors = useSelector(state => state.user.factors);

  return <div className={classes.root}>
    <WhiteTopBorder />
    <H1>利用規約</H1>
    <div className={classes.text}>
    <h2 className={classes.text}>利用規約</h2>
    <h3 className={classes.text}>
    はじめに
    </h3>
    <p className={classes.text}>
    この利用規約（以下「本規約」）は、株式会社ヒューマンロジック研究所と、株式会社コルク（以下「運営者」）が運営するウェブサイト『「一歩踏み出せない」あなたをエースにする方法 ffs-bro.com 』（以下「本サイト」）において提供される全てのサービス（以下「本サービス」）の利用における条件を、本サービスを利用する皆様（以下「ユーザー」）と運営者の間で定めるものです。
    </p>
    <h3 className={classes.text}>
    規約への同意
    </h3>
    <p className={classes.text}>
    ユーザーは、本規約の定めに従い本サービスをご利用いただきます。<br />
    未成年のユーザーは、親権者に本規約の内容を確認、同意を得た上で本サービスをご利用下さい。<br />
    ユーザーは本サービスを実際に利用することにより、本規約に同意をしたものとみなされます。
    </p>
    <h3 className={classes.text}>
    規約の変更
    </h3>
    <p className={classes.text}>
    運営者は、運営者が必要と判断した場合、事前の予告なく本規約を変更できるものとします。変更された本規約は運営者が本サイト上に掲示した時点でその効力が生じるものとし、ユーザーはこれに同意します。
    </p>
    <h3 className={classes.text}>
    各用語の定義
    </h3>
    <p className={classes.text}>
    コンテンツ：ユーザーが本サイトを利用することで得ることができる情報（診断メニュー、各種の診断結果、文章、画像、動画、音声等）を指します。
    </p>
    <h3 className={classes.text}>
    利用料金
    </h3>
    <p className={classes.text}>
    本サービスの利用は全て無料です。ただし、本サービスを利用するための通信機器、通信にかかる費用は除きます。なお今後サービスの追加拡充に伴い有料のサービスが追加された場合は、別途利用規約に定めます。
    </p>
    <h3 className={classes.text}>
    禁止事項
    </h3>
    <p className={classes.text}>
    運営者は、ユーザーが本サービスの利用に際して以下の事を行うことを禁止します。<br />
    犯罪に関わる行為、法令に違反する行為。<br />
    著作権、商標権、特許権等の知的財産権、プライバシー、肖像権等、その他第三者の権利を侵害する行為<br />
    本サービスのサーバやネットワークに支障を与える行為、スクリプト、その他の技術的な手段を利用して本サービスを不正に操作する行為、本サービスの不具合を意図的に利用する行為、その他本サービスの運営や他のユーザーによる本サービスの利用を妨害し、これらに支障を与える行為。<br />
    禁止行為によって本サービスの利用停止措置を受けたにも拘わらず、他のアカウントを利用して新たに本サービスを利用する行為。<br />
    その他、運営者が不適当と判断する行為。
    </p>
    <h3 className={classes.text}>
    禁止行為に対する措置
    </h3>
    <p className={classes.text}>
    ユーザーが本規約の禁止事項に違反した場合、運営者は、ユーザーに対する本サービスの利用停止、アクセス遮断、投稿コンテンツの非表示または削除、フィルタリング対応等の措置を、当該ユーザーへの通知なしに行うことがあります。<br />
    ユーザーが禁止行為によって本サービスの利用停止措置を受けた場合、禁止事項に該当する投稿コンテンツの削除をすることで、利用停止を解除される場合があります。
    </p>
    <h3 className={classes.text}>
    ユーザーの責任
    </h3>
    <p className={classes.text}>
    本サービスの利用に際して、必要な利用環境（通信機器・ソフトウェア・通信回線）は、ユーザーが各自の責任と費用にて用意するものとし、運営者はユーザーの利用環境について関与せず、一切の責任を負いません。<br />
    ユーザーは、自身の責任において本サービスを利用するものとし、本サービスにおいて行った全ての行為およびその結果について一切の責任を負うものとします。
    </p>
    <h3 className={classes.text}>
    損害賠償
    </h3>
    <p className={classes.text}>
    ユーザーが本規約に違反することにより運営者に損害が発生した場合、運営者は利用者に対し、この損害の賠償を請求することができ、利用者は、当該請求に直ちに応じなければならないものとします。
    </p>
    <h3 className={classes.text}>
    個人情報の取り扱い
    </h3>
    <p className={classes.text}>
    本サービスの利用に際してユーザーから取得した個人情報は、別途定める「プライバシーポリシー」に則り取り扱われます。
    </p>
    <h3 className={classes.text}>
    財産権
    </h3>
    <p className={classes.text}>
    本サービスに含まれる情報、サービス、ソフトウェアに関する財産権は運営者に帰属します。
    </p>
    <h3 className={classes.text}>
    免責事項
    </h3>
    <p className={classes.text}>
    運営者は、本サービスが安全に、安定してユーザーに提供できるよう最大限の努力を行いますが、本サービスが原因で発生したユーザーまたは他者への損害に対し、運営者に故意又は重過失が存する場合を除き一切の責任を負いません。<br />
    運営者は、予告なしに、本サービスの運営を停止または中止し、また本サイトに掲載されている情報の全部または一部を削除・変更する場合があります。
    </p>
    <h3 className={classes.text}>
    準拠法、管轄裁判所
    </h3>
    <p className={classes.text}>
    本規約は日本法を準拠法とし、東京地方裁判所を第一審の専属管轄裁判所とします。
    </p>
    <p className={classes.text}>
以上<br />
2019年11月22日制定<br />
2019年11月29日改訂
    </p>
    </div>
  </div>;
}

export default TOS;
