import { combineReducers, configureStore, getDefaultMiddleware, ActionCreator, Action } from "redux-starter-kit";
import { ThunkAction } from "redux-thunk";
import logger from "redux-logger";

import { default as rootReducer, routesMiddleware, routesEnhancer, RootState } from "./rootReducer";

const middlewares = [...getDefaultMiddleware(), routesMiddleware, logger];

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
  enhancers: [routesEnhancer],
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export default store;
