import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles, createStyles, useTheme, Theme } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { WhiteTopBorder, BlueTopBorder, BlueLineBorder, H1, BlueButton } from "./Common";
import MuttaHibito from "./MuttaHibito";

import userModule from "../modules/userModule";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    thanks: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
      textAlign: "center",
      lineHeight: 2,
      fontWeight: "bold",
      "@media (min-width: 900px)": {
        paddingTop: "20px",
        paddingBottom: "50px",
        fontSize: "12pt",
      },
      "@media (max-width: 899px)": {
        paddingTop: "20px",
        paddingBottom: "20px",
        fontSize: "9pt",
      },
    },
    instruction: {
      fontWeight: 700,
      fontSize: "12pt",
      margin: theme.spacing(3),
    },
    instruction2: {
      margin: theme.spacing(3),
      fontSize: "9pt",
    },
    questionHeader: {
      fontSize: "12pt",
      margin: "auto",
      color: theme.palette.primary.main,
      padding: "10px 0",
      textAlign: "left",
      width: "100%",
    },
    questionCheckLabel: {
      fontSize: "12pt",
    },
    image: {
      width: "90%",
    },
    topImageContainer: {
      marginTop: 20,
      lineHeight: 0,
    },
    formControl: {
      margin: theme.spacing(0),
      "@media (min-width: 900px)": {
      },
      "@media (max-width: 899px)": {
        width: "90%",
      },
    },
    tosLink: {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      cursor: "pointer",
    },
    button: {
      marginTop: 24,
    },
    bottomCopyright: {
      padding: "48px 0",
      fontSize: "9pt",
    },
  })
);

const UchuTopQuestions = (props) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const mobile = useSelector(state => state.window.mobile);

  const factors = useSelector(state => state.user.factors);

  const showTOS = () => {
    const w = window.open("/tos");
    // To avoid a bug on redux-first-router-restore-scroll to restore the current scroll
    w.onload = function() {
      setTimeout(() => {
        w.scrollTo(0, 0);
      }, 500);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!event.target.tos.checked) {
      alert("簡易診断のためには、利用規約への同意が必要です。");
      return;
    }

    let d = 0, e = 0;
    for (let i = 1; i <= 10; i++) {
      const checked = event.target["q" + i].checked;
      if (checked) {
        if (i % 2 === 0) {
          e++;
        } else {
          d++
        }
      }
    }

    dispatch(userModule.actions.setFactors({
      factors: [0, 0, 0, d, e],
    }));

    document.getElementById("main-content").scrollIntoView(true);
  };

  let mainContent;
  if (factors[3] !== null && factors[4] !== null) {
    mainContent = <MuttaHibito />;
  } else {
    mainContent = (
      <div>
        <H1>あなたは「ムッタ派」「ヒビト派」？</H1>
        <div className={ classes.instruction }>
          簡易設問でどちらのタイプか診断します。
        </div>
        <div className={ classes.instruction2 }>
          ※ここではあなたの「気質」がどちらのタイプかを簡易で診断します。<br />
        　正規のFFS理論の結果を保証するものではありませんので、ご了承ください。
        </div>

        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset" className={ classes.formControl }>
            <FormGroup>
              <div className={ classes.questionHeader }>
                ▼ 該当する項目にチェックを入れてください。
              </div>

              <FormControlLabel
                classes={{ label: classes.questionCheckLabel }}
                control={<Checkbox id="q1" value="yes" />}
                label="物事を決める時は理屈よりも自分の直感を信用する"
              />
              <FormControlLabel
                classes={{ label: classes.questionCheckLabel }}
                control={<Checkbox id="q2" value="yes" />}
                label="上司と対立するくらいなら自分の意見を変える"
              />
              <FormControlLabel
                classes={{ label: classes.questionCheckLabel }}
                control={<Checkbox id="q3" value="yes" />}
                label="話し方や説明の仕方が変わっていると言われることがある"
              />
              <FormControlLabel
                classes={{ label: classes.questionCheckLabel }}
                control={<Checkbox id="q4" value="yes" />}
                label="部下の負担になるようなことを指示するのは苦手"
              />
              <FormControlLabel
                classes={{ label: classes.questionCheckLabel }}
                control={<Checkbox id="q5" value="yes" />}
                label="思ったことはすぐに口に出す"
              />
              <FormControlLabel
                classes={{ label: classes.questionCheckLabel }}
                control={<Checkbox id="q6" value="yes" />}
                label="自分が少数派だと思われる時は会議で発言しない"
              />
              <FormControlLabel
                classes={{ label: classes.questionCheckLabel }}
                control={<Checkbox id="q7" value="yes" />}
                label="会議などで貴重な時間を無駄にしたくない"
              />
              <FormControlLabel
                classes={{ label: classes.questionCheckLabel }}
                control={<Checkbox id="q8" value="yes" />}
                label="計画が成功するかどうか、やる前から不安になる"
              />
              <FormControlLabel
                classes={{ label: classes.questionCheckLabel }}
                control={<Checkbox id="q9" value="yes" />}
                label="宴会で騒いでいる時に重要なことを思いつくことがしばしばある"
              />
              <FormControlLabel
                classes={{ label: classes.questionCheckLabel }}
                control={<Checkbox id="q10" value="yes" />}
                label="個人的な不平不満はなるべく人に言わない傾向がある"
              />
              <div>
                <Checkbox id="tos" value="yes" />
                <span className={ classes.tosLink} onClick={showTOS}>利用規約</span>に同意する
              </div>
            </FormGroup>
          </FormControl>

          <BlueButton buttonProps={{
            className: classes.button,
            type: "submit",
          }}>
            簡易診断の結果表示
          </BlueButton>
        </form>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <WhiteTopBorder />
      <div className={ classes.topImageContainer }>
        { mobile ? (
            <img src="/assets/images/top_mobile.jpg" className={ classes.image } />
          ) : (
            <img src="/assets/images/top.jpg" className={ classes.image } />
          )
        }
      </div>
      { !mobile && <BlueTopBorder /> }
      <div className={ classes.thanks }>
        日経ビジネスにて連載中の<br />
        “「一歩踏み出せない」あなたをエースにする方法”<br />
        をご覧いただきありがとうございます。
      </div>

      <main id="main-content">
        <WhiteTopBorder />
        { mainContent }
      </main>

      <div className={ classes.bottomCopyright }>
        ©Chuya Koyama/KODANSHA
      </div>
    </div>
  );
}

export default UchuTopQuestions;
