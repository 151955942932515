import { combineReducers } from "redux-starter-kit";
import { connectRoutes, canGoForward, NOT_FOUND } from "redux-first-router";
import { reducer as formReducer } from "redux-form";
import restoreScroll from "redux-first-router-restore-scroll";

import userModule from "./modules/userModule";

const routesMap = {
  ROUTE_HOME: "/",
  ROUTE_INQUERY: "/inquery",
  ROUTE_PRIVACY_POLICY: "/privacy_policy",
  ROUTE_TOS: "/tos",
  /*
  ROUTE_CLOSED: "/closed",
  ROUTE_UNSUBSCRIBE: "/unsubscribe",
  ROUTE_WITHDRAWAL: "/withdrawal",
  ROUTE_WITHDRAWAL_SUCCESS: "/withdrawal_success",
  ROUTE_QUESTIONS: {
    path: "/questions",
  },
  ROUTE_MYPAGE: "/mypage",
  ROUTE_REGISTRATION: "/registration",
  ROUTE_AUTH: "/auth",
  ROUTE_LOGIN: "/login",
  */
}

const components = {
  ROUTE_HOME: "home",
  ROUTE_QUESTIONS: "questions",
  ROUTE_MYPAGE: "mypage",
  ROUTE_REGISTRATION: "registration",
  ROUTE_INQUERY: "inquery",
  ROUTE_AUTH: "auth",
  ROUTE_LOGIN: "login",
  ROUTE_PRIVACY_POLICY: "privacy_policy",
  ROUTE_TOS: "tos",
  ROUTE_CLOSED: "closed",
  ROUTE_UNSUBSCRIBE: "unsubscribe",
  ROUTE_WITHDRAWAL: "withdrawal",
  ROUTE_WITHDRAWAL_SUCCESS: "withdrawal_success",
  [NOT_FOUND]: "not_found"
};

const routes = connectRoutes(routesMap, {
  restoreScroll: restoreScroll(),
  onAfterChange: (dispatch, getState) => {
    const { pathname } = getState().location;
    if (window.gtag) {
      window.gtag("config", "GA_MEASUREMENT_ID", {
        "page_path": pathname,
      });
    }
  },
});
const pageReducer = (state = "home", action) => components[action.type] || state;

const combinedReducer = combineReducers({
  page: pageReducer,
  location: routes.reducer,
  user: userModule.reducer,
  form: formReducer,
  window: (state, action) => {
    if (state === undefined || action.type === "window/RESIZE_WINDOW") {
      return {
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        mobile: (window.innerWidth < 900),
      };
    }
    return state;
  },
});

const rootReducer = (state, action) => {
  const newState = combinedReducer(state, action);

  // scroll to top on the new page
  if (routesMap[action.type]) {
    if (!canGoForward()) {
      window.scrollTo(0, 0);
    }
  }

  return newState;
};

export type RootState = ReturnType<typeof rootReducer>;
export const routesMiddleware = routes.middleware;
export const routesEnhancer = routes.enhancer;
export default rootReducer;
