import axiosBase from "axios";
import jstz from "jstimezonedetect";

// __P4_GIT_HASH__ is replaced by webpack.DefinePlugin
declare var __FFS_GIT_HASH__: string;

const axios = axiosBase.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": `FFS Space Bros. ${__FFS_GIT_HASH__}`
  },
  responseType: "json"
});

class API {
  static getClientInfo() {
    const timezone = jstz.determine();
    return {
      language: window.navigator.language,
      timestamp: Math.floor(Date.now() / 1000),
      timezone
    };
  }
  static assess(
    answers: Array<number>
  ) {
    return axios.post("/assess", {
      answers
    });
  }
  static register(
    factorA: number,
    factorB: number,
    factorC: number,
    factorD: number,
    factorE: number,
    stress: number,
    reliability: number,
    visardsKey: string,
    familyName: string,
    givenName: string,
    familyNameYomi: string,
    givenNameYomi: string,
    gender: string,
    birthday: string,
    email: string,
    password: string,
    organization: string,
    tel: string
  ) {
    return axios.post("/register", {
      factorA,
      factorB,
      factorC,
      factorD,
      factorE,
      stress,
      reliability,
      visardsKey,
      familyName,
      givenName,
      familyNameYomi,
      givenNameYomi,
      gender,
      birthday,
      email,
      password,
      organization,
      tel,
    });
  }
  static auth(
    email: string,
    authCodeEmail: string,
    authCodeSMS: string
  ) {
    return axios.post("/auth", {
      email,
      authCodeEmail,
      authCodeSMS,
    });
  }
  static login(
    email: string,
    password: string,
  ) {
    return axios.post("/login", {
      email,
      password,
    });
  }
  static withdraw(
    jwt: string,
    sendMail: boolean,
  ) {
    return axios.post("/withdraw", {
      jwt,
      sendMail,
    });
  }
}

export default API;
