import { createSlice, PayloadAction } from "redux-starter-kit";
import Cookie from "js-cookie";

import { AppThunk } from "../store";
import API from "../api";

interface State {
  factors: Array<number|null>;
  visardsKey: string|null;
  currentAnswerIndex: number;
  answers: { [key: number]: number; };
  answerIntervalId: any; // TODO: set proper type
  answerRestSeconds: number;
  questionIndexes: Array<number>;
  lowReliability: boolean;
  givenName: string;
  familyName: string;
  login: boolean;
  sendMail: boolean;
}

const decodeJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
};

const initialState: State = {
  // A, B, C, D, S, R
  factors: [null, null, null, null, null, null],
  visardsKey: null,
  currentAnswerIndex: -1,
  answers: {},
  answerIntervalId: null,
  answerRestSeconds: 9,
  questionIndexes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
  lowReliability: false,
  givenName: "",
  familyName: "",
  login: false,
  sendMail: false,
};

const jwt = Cookie.get("jwt");

if (jwt) {
  try {
    const j = decodeJwt(jwt);
    initialState.login = true;
  } catch (e) {
    // clear invalid jwt
    Cookie.remove("jwt");
  }
}

const userModule = createSlice({
  name: "user",
  initialState,
  reducers: {
    restartQuestions: (state, action) => {
      state.currentAnswerIndex = -1;
      state.lowReliability = true;
    },
    startQuestions: (state, action) => {
      const shuffled = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79];

      // shuffle
      for (let i = shuffled.length - 1; i > 0; i--) {
        const r = Math.floor(Math.random() * (i + 1));
        const tmp = shuffled[i];
        shuffled[i] = shuffled[r];
        shuffled[r] = tmp;
      }

      state.currentAnswerIndex = 0;
      state.answers = {};
      if (state.answerIntervalId !== null) {
        clearInterval(state.answerIntervalId);
      }
      state.answerIntervalId = null;
      state.answerRestSeconds = 9;
      state.questionIndexes = shuffled;
    },
    setAnswerIntervalId: (state, action) => {
      if (state.answerIntervalId !== null) {
        clearInterval(state.answerIntervalId);
      }
      state.answerIntervalId = action.payload.answerIntervalId;
    },
    decreaseRestSeconds: (state, action) => {
      if (state.answerRestSeconds >= 1) {
        state.answerRestSeconds--;
      }
    },
    answer: (state, action) => {
      if (state.currentAnswerIndex >= 80) {
        console.log("already answered");
        return;
      }

      const answer = action.payload.answer;
      state.answers[state.questionIndexes[state.currentAnswerIndex]] = answer;
      state.currentAnswerIndex++;
      state.answerRestSeconds = 9;
      clearInterval(state.answerIntervalId);
      state.answerIntervalId = null;
    },
    setFactors(state, action) {
      state.factors = action.payload.factors;
    },
    assessSuccess(state, action) {
      state.factors = action.payload.factors;
      state.visardsKey = action.payload.key;
    },
    assessFailed(state, action) {
      // FIXME: action.payload.errors
      console.log(action);
    },
    registrationSuccess(state, action) {
      // Do nothing!
    },
    authSuccess(state, action) {
      Cookie.set("jwt", action.payload.jwt);

      const j = decodeJwt(action.payload.jwt);
      state.login = true;
    },
    loginSuccess(state, action) {
      Cookie.set("jwt", action.payload.jwt);

      const j = decodeJwt(action.payload.jwt);
      state.login = true;
    },
    logout(state, action) {
      Cookie.remove("jwt");
      state.login = false;
    },
    setSendMail(state, action) {
      state.sendMail = action.payload.sendMail;
    },
  }
});

export default userModule;

export const assess = (
): AppThunk => async (dispatch, getState) => {
  const answers = getState().user.answers;
  if (Object.keys(answers).length !== 80) {
    dispatch(userModule.actions.assessFailed(["回答数が足りません。"]));
    return;
  }

  let answersToSend = [];
  for (let i = 0; i < 80; i++) {
    answersToSend.push(answers[i]);
  }

  if (answersToSend.length !== 80) {
    dispatch(userModule.actions.assessFailed(["回答数が足りません。"]));
    return;
  }

  API.assess(answersToSend)
    .then(res => {
      if (res.data.errors) {
        dispatch(userModule.actions.assessFailed(res.data.errors));
      } else if (res.data.body.factors[6] >= 40) {
        dispatch(userModule.actions.assessSuccess(res.data.body));
        dispatch({ type: "ROUTE_REGISTRATION", meta: { location: { kind: "redirect" } } });
      } else {
        // 再診断
        dispatch(userModule.actions.restartQuestions());
      }
    }).catch(errors => {
      console.log("assess error", errors);
      dispatch(userModule.actions.assessFailed({ _error: "診断に失敗しました。"}));
    });
};
