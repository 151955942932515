import * as React from "react";

import { makeStyles, createStyles, useTheme, Theme } from "@material-ui/core/styles";

import MuiButton from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PlayArrow from "@material-ui/icons/PlayArrow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootWhiteTopBorder: {
      width: "100%",
      height: "30px",
      backgroundSize: "40px 15px",
      backgroundColor: "white",
      backgroundImage: "url(/assets/images/blue_triangle.png)",
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
    },
    rootBlueTopBorder: {
      width: "100%",
      height: "30px",
      backgroundSize: "40px 15px",
      backgroundColor: theme.palette.primary.main,
      backgroundImage: "url(/assets/images/white_triangle.png)",
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
    },
    triangle: {
      width: 40,
      height: 15,
    },
    rootBlueLineBorder: {
      width: "100%",
      height: 15,
      textAlign: "center",
      lineHeight: "30px",
      whiteSpace: "nowrap",
    },
    blueLineBorder: {
      width: "40%",
      height: 15,
      display: "inline-block",
      borderTop: `1px solid ${theme.palette.primary.main}`,
    },
    rootHeading1: {
      margin: "33px auto 5px",
      "@media (min-width: 900px)": {
        fontSize: "20pt",
      },
      "@media (max-width: 899px)": {
        fontSize: "12pt",
      },
    },
    headingBorder: {
      width: "75px",
      height: "20px",
      margin: "auto",
      borderTop: `3px solid ${theme.palette.primary.main}`,
    },
  })
);

const useButtonStyles = makeStyles((theme) =>
  createStyles({
    root: {
      whiteSpace: "nowrap",
      border: `1px solid ${theme.palette.primary.main}`,
      height: "40px",
      padding: "1px 32px",
    },
    label: {
      fontSize: "13px",
      fontWeight: 700,
    },
    outlined: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      border: "1px solid ".concat(theme.palette.primary.main),
      "&:hover": {
        backgroundColor: "#dddddd",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "white"
        }
      }
    },
    endIcon: {
      marginRight: -20,
    },
    iconSizeMedium: {
      "& > *:first-child": {
        fontSize: 30,
      }
    }
  })
);

const useBlueButtonStyles = makeStyles((theme) =>
  createStyles({
    root: {
      whiteSpace: "nowrap",
      border: "none",
      height: "40px",
      padding: "1px 32px",
    },
    label: {
      fontSize: "13px",
      color: "white",
      fontWeight: 700,
    },
    outlined: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      border: "none",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: theme.palette.primary.main,
        }
      }
    },
    endIcon: {
      marginRight: -20,
    },
    iconSizeMedium: {
      "& > *:first-child": {
        fontSize: 30,
      }
    }
  })
);


const useSmallButtonStyles = makeStyles((theme) =>
  createStyles({
    root: {
      whiteSpace: "nowrap",
      border: "none",
      color: "white",
      backgroundColor: theme.palette.primary.main,
      "@media (min-width: 900px)": {
        width: "60px",
        height: "15px",
        lineHeight: "15px",
        padding: "1px 15px",
      },
      "@media (max-width: 899px)": {
        width: "40px",
        height: "14px", // original 10px
        lineHeight: "14px",
        padding: "1px 10px",
      },
    },
    label: {
      "@media (min-width: 900px)": {
        fontSize: "8pt",
      },
      "@media (max-width: 899px)": {
        fontSize: "6pt",
      },
      fontWeight: 700,
    },
    primary: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: theme.palette.primary.main,
        }
      }
    },
  })
);

export const WhiteTopBorder = (props) => {
  const classes = useStyles({});
  return <div className={ classes.rootWhiteTopBorder }>
  </div>;
};

export const BlueTopBorder = (props) => {
  const classes = useStyles({});
  return <div className={ classes.rootBlueTopBorder }>
  </div>;
};

export const BlueLineBorder = (props) => {
  const classes = useStyles({});
  return <div className={ classes.rootBlueLineBorder }>
    <div className={classes.blueLineBorder}></div>
    <img src="/assets/images/blue_triangle_line.png" className={ classes.triangle } />
    <div className={classes.blueLineBorder}></div>
  </div>;
};

export const H1 = (props) => {
  const classes = useStyles({});
  const { children } = props;
  return <div>
    <h1 className={ classes.rootHeading1 }>
      {children}
    </h1>
    <div className={ classes.headingBorder } />
  </div>;
};

export const Button = (props) => {
  const classes = useButtonStyles({});
  const { onClick, className, children, buttonProps } = props;

  const endIcon = <PlayArrow htmlColor="#8bc7de"  style={{ fontSize: 22 }} />;

  return <div className={className}>
      <MuiButton variant="outlined" endIcon={endIcon} onClick={onClick} {...buttonProps} classes={ classes }>
      {children}
    </MuiButton>
  </div>
};

export const BlueButton = (props) => {
  const classes = useBlueButtonStyles({});
  const { onClick, className, children, buttonProps } = props;

  return <div className={className}>
      <MuiButton variant="outlined" onClick={onClick} {...buttonProps} classes={ classes }>
      {children}
    </MuiButton>
  </div>
};

export const SmallButton = (props) => {
  const classes = useSmallButtonStyles({});
  const { onClick, className, children, buttonProps } = props;

  return <div className={className}>
      <MuiButton variant="outlined" onClick={onClick} {...buttonProps} classes={ classes }>
      {children}
    </MuiButton>
  </div>
};

export const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
};

export const renderTextField = ({
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

export const renderCheckbox = ({
  input,
  label,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox {...input} {...custom} />
      }
      label={label}
    >
    </FormControlLabel>
    {renderFormHelper({ touched, error })}
  </div>
);

export const renderSelectField = ({
  input,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error}>
    <Select
      native
      {...input}
      {...custom}
    >
      {children}
    </Select>
    {renderFormHelper({ touched, error })}
  </FormControl>
);

export const onKeyPressIgnoreEnterOnForm = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
  }
};
