// IE11 polifill
if (!Object.entries) {
  Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}
import 'react-app-polyfill/ie11';

// global variables
declare global {
  interface Window {
    gtag: any;
  }
}

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from "./components/App";
import { Provider } from "react-redux";

import store from "./store";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#app')
);

const sendResizeAction = () => {
  store.dispatch({
    type: "window/RESIZE_WINDOW",
  });
};

window.addEventListener("resize", sendResizeAction);
