import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles, createStyles, useTheme, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";

import EmailSharp from "@material-ui/icons/EmailSharp";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";

import { Button, BlueButton } from "./Common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
    },
    main: {
      width: "100%",
      color: "#ffffff",
      backgroundColor: theme.palette.primary.main,
    },
    topButtonContainer: {
      position: "relative",
    },
    topButton: {
      position: "absolute",
      top: -18,
      right: 18,
      width: 36,
      height: 36,
    },
    contents: {
      "@media (min-width: 900px)": {
        overflow: "hidden",
        height: 100,
        lineHeight: "100px",
      },
      "@media (max-width: 899px)": {
        textAlign: "center",
      },
    },
    button: {
      "@media (min-width: 900px)": {
        float: "left",
        overflow: "hidden",
        paddingLeft: 15,
      },
      "@media (max-width: 899px)": {
        paddingTop: 20,
      },
    },
    buttonButton1: {
      "@media (min-width: 900px)": {
        width: 180,
      },
      "@media (max-width: 899px)": {
      },
    },
    buttonButton2: {
      "@media (min-width: 900px)": {
        width: 200,
      },
      "@media (max-width: 899px)": {
      },
    },
    buttonButton3: {
      "@media (min-width: 900px)": {
        width: 140,
      },
      "@media (max-width: 899px)": {
      },
    },
    buttonButton4: {
      backgroundColor: "#e66826",
      "@media (min-width: 900px)": {
        width: 220,
      },
      "@media (max-width: 899px)": {
      },
    },
    copyright: {
      fontSize: "6pt",
      width: "100%",
      margin: "auto",
      padding: "0 10px 10px",
      "@media (min-width: 900px)": {
        textAlign: "right",
        marginRight: 120,
      },
      "@media (max-width: 899px)": {
        textAlign: "center",
        paddingBottom: 10,
      },
    },
  })
);

const useButtonStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      border: `1px solid ${theme.palette.primary.main}`,
      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: "#dddddd",
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          color: theme.palette.primary.contrastText,
          backgroundColor: "white",
        }
      }
    }
  })
);

const Footer = (props) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const buttonClasses = useButtonStyles({});
  const theme = useTheme();

  const { showCopyright } = props;

  const page = useSelector(state => state.page);

  const goInquery = () => dispatch({ type: "ROUTE_INQUERY" });
  const goPrivacyPolicy = () => dispatch({ type: "ROUTE_PRIVACY_POLICY" });
  const goHome = () => dispatch({ type: "ROUTE_HOME" });
  const showInqueryButton = page !== "inquery";
  const showPrivacyPolicyButton = page !== "privacy_policy";

  return <footer className={ classes.root }>
    <div className={ classes.main }>
      <div className={ classes.topButtonContainer }>
        <Fab aria-label="TOPへ戻る" className={classes.topButton} classes={ buttonClasses } onClick={() => window.scrollTo(0, 0)}>
          <ArrowDropUp fontSize="small" />
        </Fab>
      </div>
      <div className={ classes.contents }>
        { showInqueryButton &&
        <Button className={classes.button} onClick={goInquery} buttonProps={{
            className: classes.buttonButton1,
          }}>
          <EmailSharp htmlColor="#8bc7de" fontSize="small" />&nbsp;お問い合わせ
        </Button>
        }
        { showPrivacyPolicyButton &&
        <Button className={classes.button} onClick={goPrivacyPolicy} buttonProps={{
            className: classes.buttonButton2,
          }}>
          プライバシーポリシー
        </Button>
        }

        <Button className={classes.button} onClick={goHome} buttonProps={{
            className: classes.buttonButton3,
          }}>
          TOP
        </Button>
      </div>
      <div className={classes.copyright}>
        © 2019 Human Logic Laboratory, Inc./CORK, INC.
      </div>
    </div>
  </footer>;
};

export default Footer;
