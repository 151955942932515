import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { back } from "redux-first-router";

import { makeStyles, createStyles, useTheme, Theme } from "@material-ui/core/styles";

import { WhiteTopBorder, BlueTopBorder, H1, Button } from "./Common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      fontSize: "10pt",
    },
    text: {
      textAlign: "left",
      margin: "auto",
      padding: "10px 0",
      "@media (min-width: 900px)": {
        width: 600,
      },
      "@media (max-width: 899px)": {
        width: "90%",
      },
    },
  })
);

const PrivacyPolicy = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const factors = useSelector(state => state.user.factors);

  const goBack = () => {
    back();
  };

  return <div className={classes.root}>
    <WhiteTopBorder />
    <H1>プライバシーポリシー</H1>
    <div className={classes.text}>
<h2 className={classes.text}>
ご利用者の個人情報の取り扱いについて
</h2>
<p className={classes.text}>
「一歩踏み出せない」あなたをエースにする方法（以下「本ウェブサイト」といいます。）は、FFS理論に基づく診断プログラムを実施し、その結果に基づく各種診断結果をご提供すること（以下「本サービス」といいます。）を目的で構築されたものであり、FFS理論の排他的利用権を有する株式会社ヒューマンロジック研究所と、株式会社コルク（以下併せて「当社ら」といいます。）により共同で運営されています。
</p>
<p className={classes.text}>
本ウェブサイトのご利用にあたりご利用者からご提供いただく個人情報を以下の通り管理し、適切かつ適法に取り扱います。
</p>
<h3 className={classes.text}>
1. 個人情報の利用目的
</h3>
<p className={classes.text}>
当社らは、個人情報の取り扱いについて業務実態に応じた個人情報保護のための管理体制を確立するとともに、当該情報を以下の目的の範囲内で使用します。
</p>
<p className={classes.text}>
１）本サービスの提供および管理のため
２）本サービスに関する情報提供、諸連絡およびお問合せへの対応のため
３）本サービスに関するご案内及びそれに関連付随する当社らからの情報提供のため
４）本サービス、当社ら又は当社らのお取引先のマーケティング活動又はプロモーション活動のため
５）その他、本サービスの提供に伴う業務のため
６）当社らの事業活動上必要な報告、連絡及び相談のため
７）当社らの情報セキュリティを含むサービスの改善のため
</p>
<h3 className={classes.text}>
2. 個人情報の第三者への提供
</h3>
<p className={classes.text}>
当社らは、法令により認められる場合又は本プライバシーポリシーに定める場合を除き、あらかじめご利用者の同意を得ることなく、ご利用者の個人情報を第三者に提供しません。
</p>
<h3 className={classes.text}>
3. 安全管理措置
</h3>
<p className={classes.text}>
当社らは、ご利用者の個人情報を正確かつ最新の状態に保ち、当該個人情報への不正アクセス、紛失、破壊、改ざん及び漏えい等を防止するための適切な安全管理措置を講じます。
</p>
<h3 className={classes.text}>
4. 個人情報の取扱いの外部委託
</h3>
<p className={classes.text}>
当社らは、本サービスの運営等の業務を遂行するために必要な範囲で、個人情報の取扱いを伴う業務を外部の業者に委託することがあります。業務委託にあたっては、当社らは、当社らの選定基準に基づき、当社らが設定する安全対策基準を満たす業者を選定し、適切な安全管理措置が講じられるよう契約により義務づけた上で、適切に委託業者を管理・監督するものとします。
</p>
<h3 className={classes.text}>
5. 法令などの遵守について
</h3>
<p className={classes.text}>
当社らは、個人情報保護の実現のため、個人情報保護法、各省庁ガイドラインその他関連する法令等を遵守いたします。
</p>
<h3 className={classes.text}>
6.個人情報の開示、訂正及び利用停止等について
</h3>
<p className={classes.text}>
当社らは、ご利用者ご本人からご自身の個人情報の開示・訂正・利用停止等のご請求があった場合は、ご本人であることを確認させていただいたうえで、適切かつ迅速なご対応に努めます。なお、開示等のご請求に際しましては、ご本人であることを確認させていただくために、ご本人確認書類（免許証・パスポート等）の提示をお願いしておりますので、あらかじめご了承ください。
</p>
<p className={classes.text}>
個人情報の取扱いに関するお問合せ、個人情報の開示等及び苦情・相談につきましては、以下の「お問合せ窓口」までご連絡ください。
</p>
<p className={classes.text}>
お問合せ窓口
ffs-bro@human-logic.co.jp
</p>
<h3 className={classes.text}>
7. 開示に対する法的免責事項について
</h3>
<p className={classes.text}>
法律の定めにより、国、地方自治体、裁判所、警察その他法律や条例などで認められた権限を持つ機関より要請があった場合には、これに応じてご利用者の同意なく情報を開示することがあります。
</p>
<h3 className={classes.text}>
8. プライバシーポリシーの変更について
</h3>
<p className={classes.text}>
当社らは、本サービスを定期的に見直し、改善を行います。それに伴い、プライバシーポリシーを変更する場合があります。
</p>
<p className={classes.text}>
制定日：2019年11月14日
</p>
    </div>
    <Button onClick={goBack}>戻る</Button>
  </div>;
}

export default PrivacyPolicy;
