import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { back } from "redux-first-router";

import { makeStyles, createStyles, useTheme, Theme } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import { WhiteTopBorder, BlueTopBorder, H1, Button } from "./Common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    text: {
      textAlign: "left",
      margin: "auto",
      fontSize: "10pt",
      "@media (min-width: 900px)": {
        width: 600,
      },
      "@media (max-width: 899px)": {
        width: "100%",
      },
    },
    p: {
      padding: "10px 0",
    },
  })
);

const Inquery = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const goBack = () => {
    back();
  };

  return <div className={classes.root}>
    <WhiteTopBorder />
    <H1>お問い合わせ／運営会社</H1>
    <div className={classes.text}>
      <Typography className={classes.p}>
        お問い合わせは以下のメールアドレス宛にお願いします。<br />
        <a href="mailto:ffs-bro@human-logic.co.jp?subject=宇宙兄弟FFSについて問い合わせ&amp;body=問い合わせ内容をご記入ください">ffs-bro@human-logic.co.jp</a>
      </Typography>
      <Typography className={classes.p}>
        ■運営会社
        株式会社ヒューマンロジック研究所<br />
        〒108-0072 東京都港区白金1-27-6 白金高輪ステーションビル4F
      </Typography>
      <Typography className={classes.p}>
        「一歩踏み出せない」あなたをエースにする方法 運営事務局
      </Typography>
    </div>
    <Button onClick={goBack}>戻る</Button>
  </div>;
}

export default Inquery;
