import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles, createStyles, useTheme, Theme } from "@material-ui/core/styles";

import { WhiteTopBorder, BlueTopBorder, H1, BlueButton } from "./Common";
import userModule from "../modules/userModule";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    nambaResult: {
      width: 117,
      padding: "10px 0",
    },
    nambaResultLine1: {
      fontWeight: 700,
      "@media (min-width: 900px)": {
        fontSize: "9pt",
      },
      "@media (max-width: 899px)": {
        fontSize: "13pt",
      },
    },
    nambaResultLine2: {
      fontWeight: 700,
      boxShadow: "inset 0 0px 0 white, inset 0 -20px 0 #feff6e",
      width: "4.8em",
      margin: "auto",
      "@media (min-width: 900px)": {
        fontSize: "18pt",
      },
      "@media (max-width: 899px)": {
        fontSize: "24pt",
      },
    },
    descriptionContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "left",
      textAlign: "left",
      backgroundSize: "auto", // not "contain"
      backgroundRepeat: "no-repeat",
      fontSize: "10pt",
      "@media (max-width: 899px)": {
        margin: "20px 20px",
        paddingTop: 580,
        backgroundPosition: "top center",
      },
      "@media (min-width: 900px)": {
        paddingLeft: 350, // imageWidth: 320
        margin: "20px 50px",
        minHeight: 515, // imageHeight: 515
      },
    },
    descriptionMutta: {
      backgroundImage: "url(/assets/images/mutta_on_mypage.png)",
    },
    descriptionHibito: {
      backgroundImage: "url(/assets/images/hibito_on_mypage.png)",
    },
    descriptionItem: {
      "@media (max-width: 899px)": {
        width: "100%",
      },
      "@media (min-width: 900px)": {
        padding: 10,
        width: "50%",
      },
    },
    descriptionHeader: {
      borderBottom: `1px ${theme.palette.primary.main} solid`,
      padding: "2px 0",
      color: theme.palette.primary.main,
      fontWeight: 700,
      margin: "5px 0",
    },
    list: {
      listStyle: "none",
      paddingLeft: "1.5em",
      "& li": {
        margin: "4px 0",
      },
      "& li::before": {
        content: "'•'",
        color: theme.palette.primary.main,
        display: "inline-block",
        width: "1em",
        marginLeft: "-1em",
      }
    },
    headerKoma: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    komaContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      textAlign: "left",
      fontSize: "10pt",
      "@media (max-width: 899px)": {
        margin: "20px 0",
      },
      "@media (min-width: 900px)": {
        margin: "20px 50px",
      },
    },
    komaItem: {
      "@media (max-width: 899px)": {
        margin: "0 20px 20px",
        width: "100%",
      },
      "@media (min-width: 900px)": {
        padding: 10,
        width: "50%",
      },
    },
    komaImg: {
      width: "100%",
    },
    button: {
      marginTop: 24,
    },
  })
);

const MuttaHibito = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const mobile = useSelector(state => state.window.mobile);

  const factors = useSelector(state => state.user.factors);

  const v1text = factors[4] >= factors[3] ? (
    <div>
      <div className={classes.nambaResultLine1}>
        あなたの気質は「拡散性＜保全性」の
      </div>
      <div className={classes.nambaResultLine2}>
        ムッタ派
      </div>
      <div className={`${classes.descriptionContainer} ${classes.descriptionMutta}`}>
        <div className={classes.descriptionItem}>
          <div className={classes.descriptionHeader}>
            特徴
          </div>
          <ul className={ classes.list }>
            <li>じっくりやりたい</li>
            <li>確実にやりたいので、準備する。間に合わない時に躊躇することもある</li>
            <li>失敗すると引きずる</li>
            <li>仲間が欲しい(一人は寂しい)</li>
            <li>枠組みを作っている。その中で自由に振る舞う</li>
            <li>組織化、仕組化が得意</li>
            <li>農耕的</li>
          </ul>
        </div>
        <div className={classes.descriptionItem}>
          <div className={classes.descriptionHeader}>
            口癖
          </div>
          <ul className={ classes.list }>
            <li>「無理だよなぁ　ちょっとまって。準備しよう」</li>
            <li>「他はどうなんですか」</li>
            <li>「整えたい」</li>
          </ul>
        </div>
        <div className={classes.descriptionItem}>
          <div className={classes.descriptionHeader}>
            ストレッサー
          </div>
          <ul className={ classes.list }>
            <li>急な変更</li>
            <li>曖昧な指示</li>
            <li>一人ぼっち</li>
          </ul>
        </div>
        <div className={classes.descriptionItem}>
          <div className={classes.descriptionHeader}>
            ストレス時
          </div>
          <ul className={ classes.list }>
            <li>頭が真っ白</li>
            <li>逃げたくなる</li>
            <li>恥じる</li>
          </ul>
        </div>
        <div className={classes.descriptionItem}>
          <div className={classes.descriptionHeader}>
            ムッタ派の「自分の活かし方」
          </div>
          <div>
            慎重に整えたがるので、早めに情報を集めて準備しましょう。ググるよりも本をじっくり読み込むことをお勧めします。
          </div>
        </div>
      </div>
      <div className={classes.komaContainer}>
        <div className={classes.komaItem}>
          <div className={classes.headerKoma}>
            ▼強みを発揮している状態
          </div>
          <img src="/assets/images/mutta_strength.jpg" className={classes.komaImg} />
        </div>
        <div className={classes.komaItem}>
          <div className={classes.headerKoma}>
            ▼ストレス時
          </div>
          <img src="/assets/images/mutta_weakness.jpg" className={classes.komaImg} />
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className={classes.nambaResultLine1}>
        あなたの気質は「拡散性＞保全性」の
      </div>
      <div className={classes.nambaResultLine2}>
        ヒビト派
      </div>
      <div className={`${classes.descriptionContainer} ${classes.descriptionHibito}`}>
        <div className={classes.descriptionItem}>
          <div className={classes.descriptionHeader}>
            特徴
          </div>
          <ul className={ classes.list }>
            <li>すぐにやりたい</li>
            <li>直進的に動く。但し、途中で「閃く」ことで急に方向を変えることもある(脈絡がない)</li>
            <li>失敗しても無頓着</li>
            <li>一人が良い(少数派を好む)</li>
            <li>枠組みを飛び越えていく</li>
            <li>ゼロベースの発想が得意</li>
            <li>狩猟的</li>
          </ul>
        </div>
        <div className={classes.descriptionItem}>
          <div className={classes.descriptionHeader}>
            口癖
          </div>
          <ul className={ classes.list }>
            <li>「とりあえず、やろう」</li>
            <li>「めっちゃ、面白そう」「それ、やるやる」</li>
            <li>「えー、面倒くさ」</li>
          </ul>
        </div>
        <div className={classes.descriptionItem}>
          <div className={classes.descriptionHeader}>
            ストレッサー
          </div>
          <ul className={ classes.list }>
            <li>拘束されること</li>
            <li>制約が多いこと</li>
            <li>団体行動</li>
          </ul>
        </div>
        <div className={classes.descriptionItem}>
          <div className={classes.descriptionHeader}>
            ストレス時
          </div>
          <ul className={ classes.list }>
            <li>衝動的</li>
            <li>攻撃的</li>
            <li>享楽的</li>
          </ul>
        </div>
        <div className={classes.descriptionItem}>
          <div className={classes.descriptionHeader}>
            ヒビト派の「自分の活かし方」
          </div>
          <div>
            興味や好き嫌いですぐに動くので「人にも役立つ面白い」を見つけると、いい奴になれます。
          </div>
        </div>
      </div>
      <div className={classes.komaContainer}>
        <div className={classes.komaItem}>
          <div className={classes.headerKoma}>
            ▼強みを発揮している状態
          </div>
          <img src="/assets/images/hibito_strength.jpg" className={classes.komaImg} />
        </div>
        <div className={classes.komaItem}>
          <div className={classes.headerKoma}>
            ▼ストレス時
          </div>
          <img src="/assets/images/hibito_weakness.jpg" className={classes.komaImg} />
        </div>
      </div>
    </div>
  );

  const resetAll = () => {
    dispatch(userModule.actions.setFactors({
      factors: [null, null, null, null, null],
    }));

    document.getElementById("main-content").scrollIntoView(true);
  };
  const openNikkei = () => {
    const w = window.open("https://business.nikkei.com/atcl/gen/19/00077/");
  };

  const goPrivacyPolicy = () => dispatch({ type: "ROUTE_PRIVACY_POLICY" });

  return <div className={classes.root}>
    { v1text }
    <BlueButton buttonProps={{ className: classes.button }} onClick={resetAll}>TOPへ戻る</BlueButton>
    <BlueButton buttonProps={{ className: classes.button }} onClick={openNikkei}>日経ビジネス記事を読む</BlueButton>
  </div>;
}

export default MuttaHibito;
