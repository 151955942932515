import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles, createStyles, useTheme, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";

import EmailSharp from "@material-ui/icons/EmailSharp";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";

import { default as userModule } from "../modules/userModule";

import { Button, SmallButton } from "./Common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: 0,
      position: "relative",
    },
    topButton: {
      position: "absolute",
      top: 2,
      left: 2,
      zIndex: 9999,
    },
    loginButton: {
      position: "absolute",
      top: 2,
      right: 2,
      zIndex: 9999,
    }
  })
);

const Header = (props) => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const goHome = () => dispatch({ type: "ROUTE_HOME" });
  const goLogin = () => dispatch({ type: "ROUTE_LOGIN" });
  const doLogout = () => {
    dispatch(userModule.actions.logout());
    dispatch({ type: "ROUTE_HOME" });
  };

  const page = useSelector(state => state.page);
  const login = useSelector(state => state.user.login);

  //const showHomeButton = page !== "home";
  const showHomeButton = page !== "home" && page !== "mypage";

  // maintainance
  return <header className={ classes.root }>
  </header>;
  /*
  return <header className={ classes.root }>
    { showHomeButton &&
      <Button onClick={goHome} buttonProps={{
        className: classes.topButton,
      }}>
        TOPページへ
      </Button>
    }
    { login ? (
      <SmallButton onClick={doLogout} className={ classes.loginButton }>
        ログアウト
      </SmallButton>
    ) : (
      <SmallButton onClick={goLogin} className={ classes.loginButton }>
        ログイン
      </SmallButton>
    )}
    </header>;
  */
};

export default Header;
